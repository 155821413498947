import Vue from 'vue'
import Vuex from 'vuex'
import each from 'lodash/each'
import moment from 'moment'
import api from '@/api'
import router from './router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,

    usuario: {},

    permisos: [],

    /* Token */
    token: null,

    /* Mensaje */
    mensaje: {
      idMensaje: null,
      ver: false,
      texto: '',
      color: 'error',
      segundos: 0
    },

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,
    asideActiveForcedKey: null,
    isAsideRightVisible: false,

    /* Updates */
    hasUpdates: false,

    /* Overlay */
    isOverlayVisible: false,

    /* filters */
    filters: {}
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    user (state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.token) {
        state.token = payload.token
        api.setToken(state.token)
      }

      if (payload.permisos) {
        state.permisos = payload.permisos
      } else {
        state.permisos = []
      }
    },

    asignarUsuario (state, datos) {
      state.usuario = {
        id: datos.id,
        nombre: datos.nombre,
        nombreUsuario: datos.nombreUsuario,
        correo: datos.correo
      }
    },

    actualizarUsuario (state, datos) {
      state.usuario.nombre = datos.nombre
      state.usuario.correo = datos.correo
    },

    filter (state, payload) {
      if (payload.filter) {
        state.filters[payload.dataprovider] = {
          'filter': payload.filter,
          'sortColumn': payload.sortColumn,
          'sortAsc': payload.sortAsc
        }
      }
    },

    /* Full Page mode */
    fullPage (state, payload) {
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload

      each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass)
        } else {
          document.documentElement.classList.add(htmlClass)
        }
      })
    },

    /* Aside Desktop */
    asideStateToggle (state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded'

      let isExpand

      if (payload !== null) {
        isExpand = payload
      } else {
        isExpand = !state.isAsideExpanded
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName)
      } else {
        document.documentElement.classList.remove(htmlAsideClassName)
      }

      state.isAsideExpanded = isExpand
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Aside Forced Active Key (when secondary submenu is open) */
    asideActiveForcedKeyToggle (state, payload) {
      state.asideActiveForcedKey = payload && payload.menuSecondaryKey ? payload.menuSecondaryKey : null
    },

    /* Aside Right */
    asideRightToggle (state, payload = null) {
      const htmlClassName = 'has-aside-right'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideRightVisible
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideRightVisible = isShow
      state.hasUpdates = false
    },

    /* Overlay */
    overlayToggle (state, payload = null) {
      if (payload === null) {
        payload = !state.isOverlayVisible
      }

      state.isOverlayVisible = !!payload
    },

    /** Funciones de mensaje por pantalla */
    mostrarMensaje (state, mensaje) {
      state.mensaje.ver = true
      state.mensaje.texto = mensaje.texto
      state.mensaje.color = mensaje.color
    },

    esconderMensaje (state) {
      if (state.mensaje.idMensaje) {
        clearTimeout(state.mensaje.idMensaje)
      }
      state.mensaje.ver = false
    }
  },
  actions: {
    async login ({ commit, dispatch }, identificacion) {
      try {
        api.setTennant('')
        const { data } = await api.auth.post(identificacion)
        dispatch('saveUser', data)
        router.replace('/')
      } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          dispatch('mostrarMensaje', {
            texto: 'Usuario/Password no valido',
            color: 'error',
            segundos: 5
          })
        } else {
          dispatch('mostrarMensaje', {
            texto: 'Error de conexion',
            color: 'error',
            segundos: 5
          })
          // api.$forceReload()
        }
      }
    },

    saveUser ({ commit }, authData) {
      commit('user', {
        name: authData.name,
        token: authData.token,
        permisos: authData.permisos
      })
      localStorage.setItem('token', authData.token)
      localStorage.setItem('usuario', authData.name)
      const expiresAt = new Date(authData.expiresAt.toString().replace('[UTC]', ''))
      localStorage.setItem('expiresAt', expiresAt)
      localStorage.setItem('refreshToken', authData.refreshToken)
      localStorage.setItem('permisos', authData.permisos)
      api.setToken(authData.token)
    },

    /**
    * @description Verifica la sesion del usuario
    */
    tryAutoLogin ({ commit, dispatch }) {
      try {
        const token = localStorage.getItem('token')
        const usuario = localStorage.getItem('usuario')
        const permisos = localStorage.getItem('permisos').split(',')
        if (!token || !usuario) {
          dispatch('logout')
        }
        api.setTennant('')
        commit('user', {
          name: usuario,
          token: token,
          permisos: permisos
        })
        // dispatch('getAlmacenes')
        const expiresAt = new Date(localStorage.getItem('expiresAt'))
        const now = new Date()
        if (now >= expiresAt) {
          dispatch('refreshToken')
        }
      } catch (error) {
        dispatch('logout')
      }
    },
    /**
     * @description Redireccion a la ventana de login y elimina
     * los datos de sesion
     */
    logout ({ commit }) {
      commit('user', {
        name: null,
        token: null
      })
      localStorage.removeItem('token')
      localStorage.removeItem('usuario')
      // api.$forceReload()
      router.replace('/login')
    },

    /**
     * @description Vuelve a asignar un token al usuario cuando este
     * caduca
     */
    refreshToken ({ dispatch }) {
      const refreshToken = localStorage.getItem('refreshToken')
      if (!refreshToken) {
        dispatch('logout')
      }
      /*
      axios.post('/login/refreshToken', {
        refreshToken
      }).then((res) => {
        dispatch('asignarUsuario', res.data)
      }).catch(() => {
        dispatch('logout')
      })
      */
    },

    setFilter ({ commit }, filterData) {
      commit('filter', filterData)
    },

    setRefreshTimer ({ dispatch }, timeExpires) {
      setTimeout(() => {
        dispatch('refreshToken')
      }, timeExpires)
    },

    mostrarMensaje ({ commit, state }, mensaje) {
      if (state.mensaje.idMensaje) {
        clearTimeout(state.mensaje.idMensaje)
      }

      commit('mostrarMensaje', mensaje)

      state.mensaje.idMensaje = setTimeout(() => {
        commit('esconderMensaje')
      }, mensaje.segundos * 1000)
    },
    async exportToExcel ({ dispatch }, payload) {
      console.log(payload)
      try {
        const { data } = await api.excel.put(payload.procedimiento, payload.excelQuery)
        if (data.size > 0) {
          const url = window.URL.createObjectURL(new Blob([data]))
          const link = document.createElement('a')
          link.href = url
          let nombre = payload.titulo + `-${moment(new Date()).format('DD-MM-YYYY-HH-mm')}.xlsx`
          link.setAttribute('download', nombre)
          document.body.appendChild(link)
          link.click()
        } else {
          dispatch('mostrarMensaje', {
            texto: 'La excel esta vacía',
            color: 'error',
            segundos: 5
          })
        }
      } catch (error) {
        dispatch('mostrarMensaje', {
          texto: error.message,
          color: 'error',
          segundos: 5
        })
      }
    },

    async obtenerTennants ({ commit, dispatch }) {
      try {
        const { data } = await api.tennants.get()
        commit('tennants', { tennants: data })
      } catch (error) {
        dispatch('mostrarMensaje', {
          texto: error,
          color: 'error',
          segundos: 5
        })
        // api.$forceReload()
      }
    },

    async obtenerUsuario ({ dispatch, commit }) {
      try {
        const { data } = await api.proc.put('p_perfil', { accion: 'SELECT_USUARIO_ACTUAL' })
        console.info(data)
        commit('asignarUsuario', data[0])
      } catch (error) {
        dispatch('mostrarMensaje', {
          texto: error,
          color: 'error',
          segundos: 5
        })
        // api.$forceReload()
      }
    },

    async actualizarUsuario ({ dispatch, commit }, usuario) {
      try {
        const { data } = await api.proc.put('p_perfil', {
          accion: 'ACTUALIZAR_USUARIO',
          id: usuario.id,
          nombre: usuario.nombre,
          correo: usuario.correo
        })
        commit('actualizarUsuario', {
          nombre: usuario.nombre,
          correo: usuario.correo
        })

        if (data[0].mensaje === 'OK') {
          dispatch('mostrarMensaje', {
            texto: 'Guardado',
            color: 'is-success',
            segundos: 5
          })
        } else {
          dispatch('mostrarMensaje', {
            texto: data[0].mensaje,
            color: 'is-danger',
            segundos: 5
          })
        }
      } catch (error) {
        dispatch('mostrarMensaje', {
          texto: 'Ha fallado el procedimiento',
          color: 'is-danger',
          segundos: 5
        })
        // api.$forceReload()
      }
    },

    async actualizarClave ({ dispatch }, formulario) {
      try {
        const { data } = await api.proc.put('p_perfil', {
          accion: 'ACTUALIZAR_CLAVE',
          id: formulario.idUsuario,
          nuevaClave: formulario.nuevaClave,
          clave: formulario.clave
        })

        if (data[0].mensaje === 'OK') {
          dispatch('mostrarMensaje', {
            texto: 'Guardado',
            color: 'is-success',
            segundos: 5
          })
          return 'OK'
        } else {
          dispatch('mostrarMensaje', {
            texto: data[0].mensaje,
            color: 'is-danger',
            segundos: 5
          })
          return 'NOK'
        }
      } catch (error) {
        dispatch('mostrarMensaje', {
          texto: 'Ha fallado el procedimiento',
          color: 'is-danger',
          segundos: 5
        })
        return 'NOK'
      }
    }
  },
  getters: {
    mensaje (state) {
      return state.mensaje
    },
    filters (state) {
      return state.filters
    },
    usuario (state) {
      return state.usuario
    },
    permisos (state) {
      return state.permisos
    }
  }
})
