// api.js
import axios from 'axios'
import store from './store'

const API = axios.create({
  baseURL: process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_URL
    : 'http://127.0.0.1:8082'
})

const dataprovider = {
  get: provider => axios.get('dataproviders/' + provider + '.json')
}

const proc = {
  put: (procedimiento, params) => API.put('/proc/' + procedimiento, params).catch((error) => {
    const { response } = error
    if (response && response.status === 403) {
      store.dispatch('logout')
    }
  })
}

const auth = {
  post: identificacion => API.post('/login', identificacion, {
    headers: {
      'Authorization': ''
    }
  })
}

const tennants = {
  get: () => API.get('/tennants')
}

const excel = {
  put: (procedimiento, params) => API.put('/excel/' + procedimiento, params, {
    responseType: 'blob'
  })
}

const importarExcelProduccion = {
  post: (file) => API.post('/importarExcelProduccion', file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const importarCalendarioController = {
  post: (file) => API.post('/importarExcelCalendario', file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const importarExcelRedexis = {
  post: (file) => API.post('/importarExcelRedexis', file, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

const array = {
  put: (procedimiento, params) => API.put('/array/' + procedimiento, params)
}

const setToken = (token) => {
  API.defaults.headers['Authorization'] = 'Bearer ' + token
}

const setAlmacen = (almacen) => {
  API.defaults.headers['Almacen'] = almacen
}

const setTennant = (tennant) => {
  API.defaults.headers['X-Tenant-ID'] = tennant
}

export default {
  dataprovider,
  proc,
  auth,
  tennants,
  array,
  excel,
  importarExcelProduccion,
  importarExcelRedexis,
  importarCalendarioController,
  setToken,
  setAlmacen,
  setTennant
}
